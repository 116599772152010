import { GET_CLOSE, SET_CLOSE } from "../constants/hotspot-close-constants";

const initialState = {
  status: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CLOSE:
      const { status } = action;
      return { ...state, status };
    default:
      return state;
  }
};
