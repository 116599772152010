import {
  GET_DEFAULT_MODEL,
  SET_DEFAULT_MODEL,
} from "../constants/default-model-constants";

const initialState = {
  status: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DEFAULT_MODEL:
      const { status } = action;
      return { ...state, status };
    default:
      return state;
  }
};
