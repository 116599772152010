import { combineReducers, createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import colorReducer from "./reducers/color-reducer";
import hotspotReducer from "./reducers/hotspot-reducer";
import rotateReducer from "./reducers/rotate-reducer";
import backgroundColorReducer from "./reducers/background-color-reducer";
import baseColorReducer from "./reducers/base-color-reducer";
import textureReducer from "./reducers/texture-reducer";
import hotspotClickReducer from "./reducers/hotspot-click-reducer";
import loaderReducer from "./reducers/loader-reducer";
import hotspotCloseReducer from "./reducers/hotspot-close-reducer";
import meshDataReducer from "./reducers/mesh-data-reducer";
import hotspotDataReducer from "./reducers/hotspotdata-reducer";
import customConfigDataReducer from "./reducers/custom-config-data-reducer";
import customCompIdReducer from "./reducers/custom-comp-id-reducer";
import resetReducer from "./reducers/reset-reducer";
import configColorReducer from "./reducers/config-color-reducer";
import defaultModelReducer from "./reducers/default-model-reducer";
import menuStatusReducer from "./reducers/menu-status-reducer";
import layoutMapReducer from "./reducers/layout-map-reducer";
import animationReducer from "./reducers/animation-reducer";
import { watcherSaga } from "./sagas/rootSaga";
import raceNumberReducer from "./reducers/race-number-reducer";
import userDetailsReducer from "./reducers/user-details-reducer";

const reducer = combineReducers({
  color: colorReducer,
  hotspot: hotspotReducer,
  rotate: rotateReducer,
  backgroundColor: backgroundColorReducer,
  baseColor: baseColorReducer,
  texture: textureReducer,
  hotspotClick: hotspotClickReducer,
  loader: loaderReducer,
  hotspotClose: hotspotCloseReducer,
  meshData: meshDataReducer,
  hotspotData: hotspotDataReducer,
  customConfigData: customConfigDataReducer,
  customCompId: customCompIdReducer,
  reset: resetReducer,
  configColor: configColorReducer,
  defaultModel: defaultModelReducer,
  menuStatus: menuStatusReducer,
  layoutMap: layoutMapReducer,
  animation: animationReducer,
  raceNum: raceNumberReducer,
  userData: userDetailsReducer,
});

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];
const store = createStore(reducer, {}, applyMiddleware(...middleware));
sagaMiddleware.run(watcherSaga);

export default store;
