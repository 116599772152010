import "./App.css";
import React, { lazy, Suspense, useEffect, useState } from "react";
import NotFound from "./screens/notFound";
import ReactGA from "react-ga";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Loader from "../src/components/loader";
import PreLoader from "./layouts/l001/pre-loader";
import rotatePhone from "../src/assets/rotate-your-screen.gif";
import { useLocation } from "react-router-dom";

const trackers = [
  {
    trackingId: "UA-253312993-2",
    gaOptions: { name: "tracker1" },
  },
  {
    trackingId: "UA-253376101-1",
    gaOptions: { name: "tracker2" },
  },
];
ReactGA.initialize(trackers);

function App() {
  //
  const [loadTimer, setLoadTimer] = useState(false);
  const Home = lazy(() => import("./screens/home"));

  let curPage = window.location.pathname;


  useEffect(() => {
    setTimeout(() => {
      setLoadTimer(true);
    }, 5000);
  }, []);

  return (
    <>

      <div id={curPage.includes("BOUTIQUE")?"":"wrapper"}>
        <Suspense
          fallback={
            <>
              {curPage.includes("RR310") && loadTimer ? (
                <PreLoader />
              ) : (
                <Loader />
              )}
            </>
          }
        >
          <Router>
            <Routes>
              <Route exact path="/products/:route" element={<Home />}></Route>
              <Route path="/products" element={<NotFound />} />
              <Route path="*" element={<Navigate replace to="/products" />} />
            </Routes>
          </Router>
        </Suspense>
      </div>
      {!curPage.includes("BOUTIQUE") && (
          <div id="warning-message">
          <Loader loaderGif={rotatePhone} />
  
          {curPage.includes("RR310") || curPage.includes("RTR-310") ? (
            <p className="mediumFont">
              Our website is exclusively designed for landscape mode. Please
              rotate your device to landscape orientation to seamlessly experience
              the 3D view of the bike.
            </p>
          ) : (
            <p className="mediumFont">
              Our website is exclusively designed for landscape mode. Please
              rotate your device to landscape orientation to seamlessly experience
              the 3D view.
            </p>
          )}
        </div>
      )}
      
    </>
  );
}

export default App;
