import { GET_TEXTURE, SET_TEXTURE } from "../constants/texture-constants";

const initialState = {
  texture: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TEXTURE:
      const { texture } = action;
      return { ...state, texture };
    default:
      return state;
  }
};
