import { GET_RACE_NUM, SET_RACE_NUM } from "../constants/race-number-constants";

const initialState = {
  num: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_RACE_NUM:
      const { num } = action;
      return { ...state, num };
    default:
      return state;
  }
};
