import React, { useState, useEffect } from "react";
import "./pre-loader.css";
import "reactjs-popup/dist/index.css";
import "react-html5video/dist/styles.css";
import { BaseUrl } from "../../../config.js";
import PlaceholderImage from "../../../assets/rr_loader.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import axios from "axios";
import Loader from "../../../components/loader";

const PreLoader = () => {
  const [modalImage, setModalImage] = useState("");
  const [colorContent, setColorContent] = useState([]);
  const [background, setBackground] = useState([]);
  const [loaderGifs, setLoaderGifs] = useState([]);
  const [loader, setLoader] = useState(false);

  let curPage = window.location.pathname;
  const pathnameParts = curPage.split("/");
  const pId = pathnameParts[pathnameParts.length - 1];

  useEffect(() => {
    try {
      setLoader(true);
      const layoutUrl = BaseUrl() + "Layout.json";

      axios.get(layoutUrl).then((response) => {
        response?.data?.layouts?.filter((item) => {
          if (item?.lId === "L001A") {
            // axios.get(BaseUrl() + pId + "/" + item?.color).then((result) => {
            //   if (result?.status === 200) {
            //     setColorContent(result?.data);

            //   }
            // });

            setColorContent(item?.meta?.inorderLoader?.grp_color);
            setModalImage(
              BaseUrl() +
                pId +
                "/" +
                item?.meta?.inorderLoader?.grp_color[0]?.image
            );
            setBackground(BaseUrl() + pId + "/" + item?.meta?.background_url);
            setLoaderGifs(BaseUrl() + pId + "/" + item?.meta?.loader_url);
            setLoader(false);
          }
        });
      });
    } catch (error) {
      // console.log(error);
    } finally {
    }
  }, []);

  const onColorChange = (image) => {
    setModalImage(BaseUrl() + pId + "/" + image);
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="pre_loader_container">
            {background && modalImage ? (
              <>
                <LazyLoadImage
                  src={background}
                  className="background"
                  PlaceholderSrc={PlaceholderImage}
                  alt="background"
                />
                <LazyLoadImage
                  src={modalImage}
                  className="modal_img"
                  PlaceholderSrc={PlaceholderImage}
                  alt="model"
                />
              </>
            ) : (
              <Loader />
            )}
          </div>
          <div className="rr_loader_con">
            <Loader loaderGif={loaderGifs} width={"25rem"} height={"25rem"} />
          </div>
          <div className="footer_rr310_loader">
            <div className="parallelograms">
              <>
                {colorContent?.map((item) => (
                  <>
                    {item.colorCode ? (
                      <div
                        style={{ backgroundColor: "#" + item.colorCode }}
                        onClick={() => onColorChange(item?.maps[0])}
                      ></div>
                    ) : (
                      <div>
                        <LazyLoadImage
                          src={
                            BaseUrl() + "RR310/" + item?.path ||
                            PlaceholderImage
                          }
                          PlaceholderSrc={PlaceholderImage}
                          alt="Image Alt"
                          onClick={() => onColorChange(item?.image)}
                        />
                      </div>
                    )}
                  </>
                ))}
              </>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PreLoader;
