import {
  GET_HOTSPOT_DATA,
  SET_HOTSPOT_DATA,
} from "../constants/hotspotdata-constants";

const initialState = {
  status: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_HOTSPOT_DATA:
      const { data } = action;
      return { ...state, data };
    default:
      return state;
  }
};
