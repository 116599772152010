import { GET_MESH_DATA, SET_MESH_DATA } from "../constants/meshdata-constants";

const initialState = {
  status: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MESH_DATA:
      const { data } = action;
      return { ...state, data };
    default:
      return state;
  }
};
