import {
  GET_HOTSPOT_STATUS,
  SET_HOTSPOT_STATUS,
} from "../constants/hotspot-constants";

const initialState = {
  status: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_HOTSPOT_STATUS:
      const { status } = action;
      return { ...state, status };
    default:
      return state;
  }
};
