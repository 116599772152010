import {
  GET_CONFIG_COLOR,
  SET_CONFIG_COLOR,
} from "../constants/config-color-constants";

const initialState = {
  color: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CONFIG_COLOR:
      const { color } = action;
      return { ...state, color };
    default:
      return state;
  }
};
