import {
  GET_CUSTOM_COMP_ID,
  SET_CUSTOM_COMP_ID,
} from "../constants/custom-comp-id";

const initialState = {
  id: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOM_COMP_ID:
      const { id } = action;
      return { ...state, id };
    default:
      return state;
  }
};
