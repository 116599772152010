import {
  GET_CUSTOM_CONFIG_DATA,
  SET_CUSTOM_CONFIG_DATA,
} from "../constants/custom-config-data-constant";

const initialState = {
  status: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOM_CONFIG_DATA:
      const { data } = action;
      return { ...state, data };
    default:
      return state;
  }
};
