import React from "react";
import "./notFound.css";
import bikemanImg from "../../assets/page-not-found.png";

const NotFound = () => {
  return (
    <div className="container-pnf">
      <div className="heading-text mediumFont">OOPS!</div>
      <div className="bike-img">
        <img src={bikemanImg} alt="bike" />
      </div>
      <div className="sub-Heading mediumFont">Error - 404: Page not found</div>
    </div>
  );
};

export default NotFound;
