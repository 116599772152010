import { GET_ANIMATION, SET_ANIMATION } from "../constants/animation-constants";

const initialState = {
  status: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ANIMATION:
      const { status } = action;
      return { ...state, status };
    default:
      return state;
  }
};
