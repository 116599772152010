import {
  GET_BACKGROUND_COLOR,
  SET_BACKGROUND_COLOR,
} from "../constants/background-color-constants";

const initialState = {
  color: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BACKGROUND_COLOR:
      const { color } = action;
      return { ...state, color };
    default:
      return state;
  }
};
