import React from "react";
import "./loader.css";
import loaderRTR from "../../assets/loaderRTR.gif";
import loaderRR from "../../assets/loaderRR.gif";
import loaderDefault from "../../assets/loaderDefault.gif";

const Loader = ({ loaderGif, width, height }) => {
  let curPage = window.location.pathname;

  return (
    <div className="loader_container">
      <img
        style={{
          maxWidth: width ? width : "30rem",
          maxHeight: height ? height : "30rem",
        }}
        src={
          loaderGif
            ? loaderGif
            : curPage.includes("RR310")
            ? loaderRR
            : curPage.includes("RTR-310")
            ? loaderRTR
            : loaderDefault
        }
        alt="Loading the experience for you"
      />
    </div>
  );
};

export default Loader;
