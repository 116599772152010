import { GET_COLOR, SET_COLOR } from "../constants/color-constants";

const initialState = {
  color: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COLOR:
      const { color } = action;
      return { ...state, color };
    default:
      return state;
  }
};
