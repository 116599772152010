import {
  GET_BASE_COLOR,
  SET_BASE_COLOR,
} from "../constants/base-color-constants";

const initialState = {
  color: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BASE_COLOR:
      const { color } = action;
      return { ...state, color };
    default:
      return state;
  }
};
